.footer {
    background: #9f1a1e;
    line-height: 28px;
    color: #fff;
    .wp1620 {
        padding: 32px 0 55px;
        border-top: 1px solid #bf6568;
    }
    @media screen and (max-width: 1480px) {
        .wp1620 {
            padding: 32px 0 55px;
            margin: 0 40px;
        }
    }
    @media screen and (max-width: 1200px) {
        padding: 0;
        .wp1620 {
            display: block;
            padding: 0;
            margin: 0;
        }
    }
    a {
        color: #fff;
        &:hover {
            opacity: .85;
        }
    }
    &-top {
        padding: 70px 0 50px;
        background: #b81c22;
        .wp1620 {
            display: flex;
        }
        @media screen and (max-width: 1200px) {
            padding: px2rem(50px) 0 px2rem(55px);
            text-align: center;
            background: #f2f2f2;
            .wp1620 {
                display: block;
            }
        }
    }
    &-ewm {
        margin-right: 110px;
        width: 400px;
        .p {
            margin-bottom: 25px;
            font-size: 15px;
            line-height: 28px;
            color: #fff;
            p {
                position: relative;
                padding-left: 30px;
            }
            .iconfont {
                position: absolute;
                left: 0;
                top: 3px;
                font-size: 18px;
            }
        }
        .ewm-list {
            font-size: 0;
            li {
                display: inline-block;
                vertical-align: top;
                margin-right: 14px;
                text-align: center;
                .img {
                    margin: 0 auto;
                    padding: 14px;
                    width: 110px;
                    background: #fff;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                p {
                    margin-top: 5px;
                    font-size: 12px;
                    color: #f3aaaa;
                }
            }
        }
        @media screen and (max-width: 1700px) {
            margin-right: 60px;
        }
        @media screen and (max-width: 1440px) {
            margin-right: 30px;
            width: 340px;
            .p {
                margin-bottom: 20px;
                font-size: 13px;
                line-height: 26px;
                p {
                    padding-left: 24px;
                }
                .iconfont {
                    top: 4px;
                    font-size: 16px;
                }
            }
            .ewm-list {
                li {
                    .img {
                        padding: 10px;
                    }
                }
            }
        }
        @media screen and (max-width: 1200px) {
            display: inline-block;
            margin: 0 auto;
            width: auto;
            .p {
                margin-bottom: 25px;
                text-align: left;
                font-size: 15px;
                line-height: 28px;
                color: #555;
                p {
                    padding-left: 30px;
                }
                .iconfont {
                    top: 3px;
                    font-size: 18px;
                }
            }
            .ewm-list {
                li {
                    margin: 0 8px;
                    p {
                        color: #555;
                    }
                }
            }
        }
        @media screen and (max-width: 640px) {
            .p {
                margin-bottom: 20px;
                font-size: 14px;
                line-height: 22px;
                p {
                    margin-bottom: 6px;
                    padding-left: 24px;
                }
                .iconfont {
                    font-size: 16px;
                }
            }
            .ewm-list {
                li {
                    margin: 0 6px;
                    .img {
                        width: 100px;
                    }
                    p {
                        margin-top: 4px;
                        font-size: 11px;
                    }
                }
            }
        }

    }
    &-nav {
        flex: 1;
        display: flex;
        justify-content: space-between;
        margin-top: 2px;
        dl {
            padding-left: 12px;
        }
        dt {
            margin-bottom: 25px;
            a {
                position: relative;
                font-size: 16px;
                color: #fff;
                &:before, &:after {
                    content: "";
                    position: absolute;
                    left: -12px;
                    top: 50%;
                    width: 2px;
                    background: #fff;
                }
                &:before {
                    margin-top: -6px;
                    height: 7px;
                }
                &:after {
                    margin-top: 3px;
                    height: 2px;
                }
                &:hover {
                    opacity: .85;
                }
            }
        }
        dd {
            margin-bottom: 12px;
            a {
                font-size: 14px;
                color: #f3aaaa;
                &:hover {
                    color: #fff;
                }
            }
        }
        @media screen and (max-width: 1440px) {
            dt {
                margin-bottom: 20px;
                a {
                    font-size: 14px;
                }
            }
            dd {
                margin-bottom: 12px;
                a {
                    font-size: 12px;
                }
            }
        }
        @media screen and (max-width: 1200px) {
            display: none;
        }
    }
    &-link {
        margin-bottom: 8px;
        font-size: 0;
        span, a {
            position: relative;
            display: inline-block;
            vertical-align: top;
            font-size: 14px;
        }
        a {
            margin-right: 21px;
        }
        a:not(:last-child):after {
            content: "";
            position: absolute;
            right: -11px;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 9px;
            background: #d4adb1;
        }
        @media screen and (max-width: 1200px) {
            margin-bottom: 0;
            padding: 40px;
            background: #b81b22;
        }
        @media screen and (max-width: 1024px) {
            padding-left: px2rem(30px);
            padding-right: px2rem(30px);
        }
        @media screen and (max-width: 640px) {
            padding-top: 20px;
            padding-bottom: 20px;
            span, a {
                font-size: 13px;
            }
            a {
                margin-right: 13px;
            }
            span:after, a:not(:last-child):after {
                right: -7px;
            }
        }
    }
    &-p {
        text-align: center;
        font-size: 12px;
        a,span {
            margin-left: 6px;
            display: inline-block;
            vertical-align: top;
        }
        @media screen and (max-width: 1200px) {
            margin: 0 40px;
            padding: 30px 0 40px;
            line-height: 22px;
            border-top: 1px dashed #bf6568;
        }
        @media screen and (max-width: 1024px) {
            margin: 0 px2rem(30px);
        }
        @media screen and (max-width: 640px) {
            padding: 22px 0 25px;
        }
    }
}

.fixed-right {
    position: fixed;
    bottom: 15%;
    right: 0;
    width: 64px;
    background-color: #fafafa;
    border: 1px solid #dfdedb;
    z-index: 666;
    li {
        height: 68px;
        text-align: center;
        font-size: 12px;
        color: #777;
        transition: all .3s;
        &:hover {
            color: #fff;
            background: $red;
            .iconfont {
                color: #fff;
            }
            a {
                color: #fff;
            }
        }
        &:not(:first-child) {
            border-top: 1px solid #dfdedb;
        }
        a {
            display: block;
            padding-top: 10px;
            height: 100%;
            color: #777;
            box-sizing: border-box;
            transition: all .3s;
            p {
                margin-top: 2px;
            }
        }
    }
    .iconfont {
        font-size: 28px;
        color: #666;
    }
    .back-top {
        display: none;
        line-height: 68px;
        cursor: pointer;
    }
    @media screen and (max-width: 1440px) {
        width: 58px;
        li {
            height: 58px;
            a {
                padding-top: 6px;
            }
        }
        .iconfont {
            font-size: 24px;
        }
        .back-top {
            line-height: 58px;
        }
    }
    @media screen and (max-width: 1200px) {
        display: none;
    }
}