
$red: #bf3434;
$red2: #ce2d25;

.orange {
    color: #d9a972;
}

.wp1350 {
    max-width: 1350px;
    margin: 0 auto;
    @media screen and (max-width: 1440px) {
        max-width: 1200px;
    }
    @media screen and (max-width: 1280px) {
        max-width: none;
        padding: 0 40px;
    }
    @media screen and (max-width: 1024px) {
        padding: 0 px2rem(30px);
    }
}
.wp1620 {
    max-width: 1620px;
    margin: 0 auto;
    @media screen and (max-width: 1700px) {
        max-width: 1400px;
    }
    @media screen and (max-width: 1480px) {
        max-width: none;
        padding: 0 40px;
    }
    @media screen and (max-width: 1024px) {
        padding: 0 px2rem(30px);
    }
}
.wp1300 {
    max-width: 1300px;
    margin: 0 auto;
    @media screen and (max-width: 1440px) {
        max-width: 1200px;
    }
    @media screen and (max-width: 1280px) {
        max-width: none;
        padding: 0 40px;
    }
    @media screen and (max-width: 1024px) {
        padding: 0 px2rem(30px);
    }
}
.wp1260 {
    position: relative;
    max-width: 1260px;
    margin: 0 auto;
    z-index: 8;
    @media screen and (max-width: 1340px) {
        max-width: none;
        padding: 0 40px;
    }
    @media screen and (max-width: 1024px) {
        padding: 0 px2rem(20px);
    }
}
.wp1200 {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    z-index: 8;
    @media screen and (max-width: 1280px) {
        max-width: none;
        padding: 0 40px;
    }
    @media screen and (max-width: 1024px) {
        padding: 0 px2rem(20px);
    }
}
.wp1126 {
    position: relative;
    max-width: 1126px;
    margin: 0 auto;
    z-index: 8;
    @media screen and (max-width: 1200px) {
        max-width: none;
        padding: 0 40px;
    }
    @media screen and (max-width: 1024px) {
        padding: 0 px2rem(20px);
    }
}
.wp1328 {
    position: relative;
    max-width: 1328px;
    margin: 0 auto;
    z-index: 8;
    @media screen and (max-width: 1400px) {
        max-width: none;
        padding: 0 40px;
    }
    @media screen and (max-width: 1024px) {
        padding: 0 px2rem(20px);
    }
}
.wp1704 {
    position: relative;
    max-width: 1704px;
    margin: 0 auto;
    z-index: 8;
    @media screen and (max-width: 1800px) {
        max-width: none;
        padding: 0 40px;
    }
    @media screen and (max-width: 1024px) {
        padding: 0 px2rem(20px);
    }
}


.dn750 {
    @media screen and (max-width: 750px) {
        display: none;
    }
}
.dk750 {
    display: none;
    @media screen and (max-width: 750px) {
        display: block;
    }
}

.inside-banner {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: (336/1920)*100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    &-mobile {
        width: 100%;
        height: 0;
        padding-bottom: (400/750)*100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        display: none;
    }
    @media screen and (max-width: 750px) {
        display: none;
        &-mobile {
            display: block;
        }
    }
}

.search-page {
    padding: 40px 0 60px;  
    @media screen and (max-width: 750px) {
        padding: 0 0 .1rem;
    }
}


.page {
    position: relative;
    background: #f7f6f3;
    overflow: hidden;
    &:before, &:after {
        content: '';
        position: absolute;
    }
    &:before {
        top: 110px;
        left: (90/1920)*100%;
        width: 132px;
        height: 425px;
        background: url(../images/bg1.png) center no-repeat;
        background-size: 100% auto;
    }
    &:after {
        right: 0;
        bottom: 140px;
        width: (337/1920)*100%;
        height: 0;
        padding-bottom: (270/337)*(337/1920)*100%;
        background: url(../images/bg2.png) center top no-repeat;
        background-size: 100% auto;
    }
    @media screen and (max-width: 1720px) {
        &:before {
            left: 2%;
        }
    }
    @media screen and (max-width: 1600px) {
        &:before {
            width: 100px;
        }
    }
    @media screen and (max-width: 1460px) {
        &:before {
            width: 80px;
        }
    }
    @media screen and (max-width: 1200px) {
        &:before {
            width: (132/1920)*100%;
            height: 0;
            padding-bottom: (425/132)*(132/1920)*100%;
        }
    }
}

.honor-page {
    &:before {
        left: 0;
    }
}

.article-page {
    padding: 65px 0 60px;
    @media screen and (max-width: 1024px) {
        padding: px2rem(50px) 0 px2rem(60px);
    }
}

.list-page {
    padding: 36px 0 55px;
    min-height: calc( 100vh - 652px);
    @media screen and (max-width: 1024px) {
        min-height: calc(100vh - .46rem - 400px);
    }
    @media screen and (max-width: 640px) {
        padding: px2rem(50px) 0 px2rem(60px);
    }
}

.feedback-page {
    &:after {
        right: 3%;
    }
}

.inside-menu {
    font-size: 0;
    overflow: hidden;
    &.no-init {
        .swiper-wrapper {
            width: 100%;
            display: block;
            text-align: center;
        }
    }
    .swiper-wrapper {
        height: auto;
    }
    a {
        display: inline-block;
        vertical-align: top;
        margin: 0 10px;
        padding: 0 10px;
        min-width: 111px;
        width: auto;
        height: 46px;
        text-align: center;
        line-height: 46px;
        font-size: 15px;
        color: #444;
        background: url(../images/menu_bg2.png) no-repeat;
        background-size: 100% 100%;
        transition: all .3s;
        &:hover, &.on {
            color: $red;
        }
    }
    @media screen and (max-width: 950px) {
        a {
            min-width: 97px;
            font-size: 14px;
            height: 40px;
            line-height: 40px;
        }
    } 
    @media screen and (max-width: 640px) {
        a {
            margin: 0 3px;
            min-width: 84px;
            font-size: 13px;
            height: 35px;
            line-height: 35px;
        }
    }
}

.brand-menu a {
    font-size: 16px;
    @media screen and (max-width: 950px) {
        font-size: 15px;
    }    
    @media screen and (max-width: 640px) {
        font-size: 14px;
    }
}

.inside-menu2 {
    margin: 20px 0 26px;
    font-size: 0;
    overflow: hidden;
    &.no-init {
        .swiper-wrapper {
            width: 100%;
            display: block;
            text-align: center;
        }
    }
    a {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: auto;
        padding: 0 16px;
        font-size: 16px;
        color: #676767;
        transition: all .3s;
        &:not(:first-child):before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -4px;
            width: 1px;
            height: 8px;
            background: #b8b3af;
        }
        &:hover, &.on {
            color: $red;
        }
    }
    @media screen and (max-width: 950px) {
        a {
            font-size: 15px;
        }
    } 
    @media screen and (max-width: 640px) {
        margin: 14px 0 16px;
        a {
            padding: 0 12px;
            font-size: 14px;
        }
    }
}

.paged {
    padding-top: 30px;
    text-align: center;
    font-size: 0;
    @media screen and (max-width: 640px) {
        padding-top: 20px;
    }
}
.paged a,
.paged span,
.paged .p_info {
    display: inline-block;
    vertical-align: top;
    margin: 0 2px 2px;
    padding: 0 13px;
    font-size: 14px;
    text-align: center;
    border: 1px solid #efeeeb;
    background: #fff;
    color: #333;
    height: 35px;
    line-height: 35px;
    @media screen and (max-width: 640px) {
        padding: 0 11px;
        font-size: 13px;
        height: 32px;
        line-height: 32px;
    }
}
.page .prev, .page .next {
    padding: 0 32px;
     @media screen and (max-width: 640px){
        padding: 0 22px;
     }
}
.paged .current,
.paged a:hover,
.paged span {
    border-color: $red2;
    background: $red2;
    color: #fff;
}


.article-swiper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.8);
    touch-action: none;
    z-index: 8888;
    .swiper-zoom-container {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
    }
    .swiper-pagination {
        bottom: 1em;
        color:#fff;
    }
    .swiper-slide {
        img {
            max-width: 95%;
            max-height: calc(100% - 6em);
            vertical-align: middle;
        }
        p {
            position: absolute;
            top: 1em;
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 16px;
            color: #fff;
            z-index: 22;
        }
    }
}