@font-face {
    font-family: 'vonnesTTBold';
    src: url('../font/vonnesTTBold.eot');
    src: url('../font/vonnesTTBold.woff') format('woff'),
        url('../font/vonnesTTBold.ttf') format('truetype'),
        url('../font/vonnesTTBold.svg') format('svg');
    font-style: normal;
}

.news-list {
    margin-top: 30px;
    @media screen and (max-width: 640px) {
        margin-top: px2rem(50px);
    }
    &-ul {
        li {
            &:not(:first-child) {
                margin-top: px2rem(23px);
            }
            a {
                display: flex;
                align-items: center;
                padding: 25px 50px 25px 25px;
                border: 1px solid #efeeeb;
                background: #fff;
                &:hover {
                    .info h3 {
                        color: $red2;
                        transition: all .3s;
                    }
                    .time {
                        background: $red2;
                        transition: all .3s;
                        strong, p {
                            color: #fff;
                            transition: all .3s;
                        }
                        &:after {
                            opacity: 1;
                            visibility: visible;
                            transition: all .3s;
                        }
                    }
                }
            }
            .img {
                margin-right: 40px;
                width: 260px;
                &-box {
                    position: relative;
                    height: 0;
                    padding-bottom: (357/569)*100%;
                    overflow: hidden;
                }
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
            .img ~ .info {
                padding: 0;
            }
            .info {
                flex: 1;
                margin-right: 65px;
                padding-bottom: 20px;
                width: 0;
                h3 {
                    margin-bottom: 12px;
                    font-size: 22px;
                    line-height: 28px;
                    color: #000;
                    span {
                        position: relative;
                        display: inline-block;
                        vertical-align: top;
                        max-width: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        box-sizing: border-box;
                    }
                }
                .new-tag {
                    span {
                        margin-top: -10px;
                        padding: 16px 36px 0 0;
                        &:after {
                            content: "\e711";
                            position: absolute;
                            right: 0;
                            top: 0;
                            font-family: 'iconfont';
                            font-size: 18px;
                            line-height: 1;
                            color: #df9f46;
                        }
                    }
                }
                .p {
                    max-height: 3*28px;
                    font-size: 13px;
                    line-height: 28px;
                    color: #666;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    word-break: break-all;
                }
            }
            .time {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 85px;
                height: 85px;
                line-height: 1;
                font-family: 'vonnesTTBold';
                transition: all .3s;
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    width: 106px;
                    height: 106px;
                    opacity: 0;
                    visibility: hidden;
                    background: url(../images/date_bg.png) center center no-repeat;
                    background-size: 100% auto;
                }
                strong {
                    display: block;
                    font-size: 48px;
                    color: $red2;
                }
                p {
                    font-size: 14px;
                    color: #666;
                }
            }
            @media screen and (max-width: 1200px) {
                .img {
                    margin-right: 30px;
                }
                .info {
                    margin-right: 40px;
                    h3 {
                        margin-bottom: 10px;
                        font-size: 20px;
                    }
                    .new-tag {
                        span {
                            padding: 13px 32px 0 0;
                            &:after {
                                font-size: 16px;
                            }
                        }
                    }
                }
                .time {
                    width: 75px;
                    height: 75px;
                    &:after {
                        width: 95px;
                        height: 95px;
                    }
                    strong {
                        font-size: 44px;
                    }
                    p {
                        font-size: 13px;
                    }
                }
            }
            @media screen and (max-width: 950px) {
                a {
                    padding: 20px 30px 20px 20px;
                }
                .img {
                    margin-right: 25px;
                    width: 220px;
                }
                .info {
                    margin-right: 25px;
                    padding: 15px 0;
                    h3 {
                        font-size: 17px;
                        line-height: 22px;
                    }
                    .p {
                        max-height: 3*26px;
                        line-height: 26px;
                    }
                }
                .time {
                    width: 65px;
                    height: 65px;
                    &:after {
                        width: 82px;
                        height: 82px;
                    }
                    strong {
                        font-size: 40px;
                    }
                    p {
                        font-size: 12px;
                    }
                }
            }
            @media screen and (max-width: 768px) {
                a {
                    padding: px2rem(20px);
                }
                .img {
                    margin-right: 10px;
                    width: 136px;
                }
                .info {
                    margin-right: 0;
                    padding: 8px 0;
                    h3 {
                        margin-bottom: 6px;
                        font-size: 16px;
                    }
                    .new-tag {
                        span {
                            margin-top: -8px;
                            padding: 10px 28px 0 0;
                            &:after {
                                font-size: 14px;
                            }
                        }
                    }
                    .p {
                        max-height: 2*22px;
                        font-size: 12px;
                        line-height: 22px;
                        -webkit-line-clamp: 2;
                    }
                }
                .time {
                    display: none;
                }
            }
        }
    }
}


.news-main {
    padding: 60px 90px 70px;
    background: #fff;
    border: 1px solid #efeeeb;
    @media screen and (max-width: 1200px) {
        padding: 50px 40px 60px;
    }
    @media screen and (max-width: 950px) {
        padding: px2rem(40px) px2rem(20px);
    }
}
.news-title {
    padding-bottom: 40px;
    text-align: center;
    color: #333;
    h2 {
        max-width: 90%;
        margin: 0 auto 4px;
        font-size: 30px;
        line-height: 1.3;
    }
    p {
        font-size: 14px;
        span {
            margin: 0 6px;
            display: inline-block;
        }
    }
    @media screen and (max-width: 1200px) {
        padding-bottom: 30px;
        h2 {
            font-size: 24px;
        }
        p {
            font-size: 13px;
        }
    }
    @media screen and (max-width: 950px) {
        padding-bottom: 25px;
        h2 {
            font-size: 22px;
        }
    }
    @media screen and (max-width: 640px) {
        padding-bottom: 15px;
        h2 {
            margin-bottom: 2px;
            font-size: 18px;
        }
        p {
            font-size: 12px;
        }
    }
}
.news-article {
    color: #666;
    @include articleReset(14px,28px);
    p {
        margin-bottom: 20px;
    }
    @media screen and (max-width: 1200px) {
        p {
            margin-bottom: 15px;
        }
    }
    @media screen and (max-width: 640px) {
        p {
            margin-bottom: 10px;
        }
    }
}

.news-attachment {
    display: flex;
    margin-top: 40px;
    line-height: 24px;
    font-size: 15px;
    color: #333;
    a {
        flex: 1;
        min-width: 0;
        color: $red;
        &:hover {
            text-decoration: underline;
        }
    }
    @media screen and (max-width: 1200px) {
        margin-top: px2rem(30px);
        font-size: 14px;
    }
}

.news-paged {
    display: flex;
    margin-top: px2rem(40px);
    li {
        flex: 1;
        text-align: center;
        &:not(:first-child) {
            border-left: 1px dashed #d6d5d3;
        }
        a {
            margin: 0 auto;
            display: block;
            max-width: 221px;
            height: 46px;
            line-height: 46px;
            text-align: center;
            font-size: 14px;
            color: #333;
            background: url(../images/page_bg.png) center no-repeat;
            background-size: 100% 100%;
            &:hover {
                color: $red;
            }
        }
        i {
            font-size: 14px;
        }
    }
    .paged-backlist {
        i {
            margin-right: px2rem(10px);
            font-size: 16px;
        }
    }
    .paged-prev {
        i {
            margin-right: px2rem(10px);
        }
    }
    .paged-next {
        i {
            margin-left: px2rem(10px);
        }
    }
    @media screen and (max-width: 950px) {
        li {
            a {
                max-width: 173px;
                height: 36px;
                line-height: 36px;
                font-size: 13px;
            }
            i {
                font-size: 13px;
            }
        }
    }
    @media screen and (max-width: 640px) {
        li {
            a {
                max-width: 88%;
                background-image: url(../images/page_bg2.png);
            }
        }
    }
}


// notice
.notice-list {
    &-ul {
        border: 1px solid #efeeeb;
        li {
            a {
                display: flex;
                align-items: center;
                padding: 0 90px;
                color: #666;
                transition: all .3s;
                &:hover {
                    h3 {
                        color: $red;
                    }
                    i {
                        transform: rotateY(180deg);
                        transition: all .3s;
                    }
                }
            }
            h3 {
                position: relative;
                flex: 1;
                min-width: 0;
                padding: 25px 30px;
                line-height: 24px;
                font-size: 16px;
                transition: all .3s;
                span {
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    max-width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    box-sizing: border-box;
                }
            }
            .new-tag {
                span {
                    margin-top: -14px;
                    padding: 14px 32px 0 0;
                    &:after {
                        content: "\e711";
                        position: absolute;
                        right: 0;
                        top: 0;
                        font-family: 'iconfont';
                        font-size: 16px;
                        line-height: 1;
                        color: #df9f46;
                    }
                }
            }
            &:nth-child(odd) {
                background: #fbfbfa;
            }
            &:nth-child(even) {
                background: #fff;
            }
            i {
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -13px;
                width: 20px;
                height: 22px;
                background: url(../images/notice1.png) center no-repeat;
                background-size: contain;
            }
            @media screen and (max-width: 1366px) {
                a {
                    padding: 0 50px;
                }
            }
            @media screen and (max-width: 950px) {
                a {
                    padding: 0 px2rem(30px);
                }
                h3 {
                    padding: 20px 24px;
                    font-size: 15px;
                }
                .new-tag {
                    span {
                        margin-top: -11px;
                        padding: 11px 28px 0 0;
                        &:after {
                            font-size: 14px;
                        }
                    }
                }
                i {
                    margin-top: -10px;
                    width: 16px;
                    height: 18px;
                }
                .time {
                    font-size: 13px;
                }
            }
            @media screen and (max-width: 640px) {
                h3 {
                    padding: 16px px2rem(30px) 16px 20px;
                    font-size: 14px;
                }
                .new-tag {
                    span {
                        margin-top: -9px;
                        padding: 9px 26px 0 0;
                        &:after {
                            font-size: 13px;
                        }
                    }
                }
                i {
                    margin-top: -9px;
                    width: 14px;
                    height: 16px;
                }
                .time {
                    font-size: 12px;
                }
            }
        }
    }
}

.notice-main {
    margin-top: 30px;
    padding: 50px 90px 60px;
    background: #fff;
    @media screen and (max-width: 1200px) {
        padding-right: 40px;
        padding-left: 40px;
    }
    @media screen and (max-width: 950px) {
        padding: .2rem .1rem;
    }
    @media screen and (max-width: 640px) {
        margin-top: px2rem(50px);
    }
    .stock-iframe {
        position: relative;
        margin: 0 auto;
        width: 560px;
        height: 0;
        padding-bottom: (430/550)*(550/1150)*100%;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}