// brand详情页
.brand-page {
    padding: 30px 0 80px;
    @media screen and (max-width: 1024px) {
        margin-top: px2rem(92px);
        padding-bottom: px2rem(60px);
    }
    @media screen and (max-width: 640px) {
        padding-top: px2rem(36px);
    }
}
@font-face {
    font-family: 'raleway';
    src: url('../font/raleway.eot');
    src: url('../font/raleway.woff') format('woff'),
    url('../font/raleway.ttf') format('truetype'),
    url('../font/raleway.svg') format('svg');
    font-style: normal;
}
.article-backlist {
    margin-bottom: 26px;
    text-align: right;
    a {
        display: inline-block;
        width: 67px;
        height: 67px;
        line-height: 67px;
        text-align: center;
        font-size: 16px;
        color: #333;
        border-radius: 50%;
        background: url(../images/back_bg.png) center center no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        transition: all .3s;
        &:hover {
            color: $red;
        }
    }
    @media screen and (max-width: 950px) {
        margin-bottom: 20px;
        a {
            width: 57px;
            height: 57px;
            line-height: 57px;
            font-size: 15px;
        }
    }
    @media screen and (max-width: 640px) {
        margin-bottom: 12px;
        a {
            width: 47px;
            height: 47px;
            line-height: 47px;
            font-size: 13px;
        }
    }
}
.brand-detail {
    display: flex;
    padding: 55px 10px 50px 50px;
    background: #fff;
    @media screen and (max-width: 1200px) {
        padding: 30px 10px 30px 30px;
    }
    @media screen and (max-width: 950px) {
        display: block;
        padding: px2rem(30px) 0 px2rem(50px) px2rem(30px);
    }
    &-left {
        width: 645px;
        overflow: hidden;
        @media screen and (max-width: 1200px) {
            width: 500px;
        }
        @media screen and (max-width: 950px) {
            padding-right: px2rem(30px);
            width: 100%;
        }
    }
    .big-swiper {
        margin-bottom: 5px;
        .swiper-slide {
            span {
                position: relative;
                display: block;
                width: 100%;
                height: 0;
                padding-bottom: (466/645)*100%;
                overflow: hidden;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }
    .thumb-swiper {
        padding: 0 46px;
        position: relative;
        .swiper-slide {
            width: (100%/6);
            span {
                position: relative;
                display: block;
                width: 100%;
                padding-bottom: (66/91)*100%;
                overflow: hidden;
                cursor: pointer;
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
            }
        }
        .swiper-slide-thumb-active {
            span {
                &:before {
                    position: absolute;
                    display: block;
                    content: '';
                    z-index: 2;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    border: 2px solid $red;
                    box-sizing: border-box;
                }
            }
        }
        @media screen and (max-width: 1200px) {
            padding: 0 36px;
            .swiper-slide {
                width: (100%/5);
            }
        }
    }
    .thumb-prev,
    .thumb-next {
        position: absolute;
        top: 0;
        width: 40px;
        height: 100%;
        text-align: center;
        font-size: 14px;
        color: #000;
        background: #eee;
        cursor: pointer;
        outline: none;
        transition: all .3s;
        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
        }
        &:hover {
            color: #fff;
            background: $red;
        }
        @media screen and (max-width: 1200px) {
            width: 30px;
        }
    }
    .thumb-prev {
        right: 0;
        &:before {
            transform: translate(-50%, -50%);
        }
    }
    .thumb-next {
        left: 0;
        &:before {
            transform: translate(-50%, -50%) rotate(180deg);
        }
    }
    &-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 60px;
        padding: 32px 0 44px;
        overflow: hidden;
        @media screen and (max-width: 1200px) {
            margin-left: 40px;
            padding: 15px 0;
        }
        @media screen and (max-width: 950px) {
            display: block;
            margin-left: 0;
            padding: px2rem(40px) px2rem(15px) 0 0;
        }
        .detail-title {
            position: relative;
            flex-grow: 0;
            flex-shrink: 0;
            padding-bottom: 25px;
            line-height: 1.2;
            h2 {
                font-size: 36px;
                color: #333;
                font-weight: 300;
            }
            h3 {
                font-size: 36px;
                color: #494949;
                font-family: 'raleway';
                font-weight: 600;
            }
            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 30px;
                height: 1px;
                background: #de3635;
                border: 1px solid #eb8585;
            }
            @media screen and (max-width: 1200px) {
                padding-bottom: 18px;
                h2, h3 {
                    font-size: 30px;
                }
            }
            @media screen and (max-width: 950px) {
                text-align: center;
                h2, h3 {
                    font-size: 26px;
                }
                &:after {
                    left: 50%;
                    margin-left: -15px;
                }
            }
            @media screen and (max-width: 640px) {
                padding-bottom: 15px;
                h2, h3 {
                    font-size: 20px;
                }
                &:after {
                    border-bottom: none;
                }
            }
        }
        .scroll-con {
            position: relative;
            flex-grow: 1;
            flex-shrink: 1;
            margin: 25px 0 20px;
            overflow: hidden;
            &-box {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            .scroll-wp {
                position: relative;
                padding-right: 70px;
                font-size: 14px;
                line-height: 30px;
                color: #666;
            }
            p {
                margin-bottom: 8px;
            }
            @media screen and (max-width: 1200px) {
                margin-top: 20px;
                .scroll-wp {
                    padding-right: 40px;
                }
            }
            @media screen and (max-width: 950px) {
                height: 300px;
            }
            @media screen and (max-width: 640px) {
                margin-top: 16px;
                .scroll-wp {
                    padding-right: 16px;
                    line-height: 28px;
                }
            }
        }
        .iScrollVerticalScrollbar {
            background: #e5ddd5;
            border-radius: 7px;
            @media screen and (max-width: 640px) {
                width: 5px !important;
            }
        }
        .iScrollIndicator {
            border: 1px solid #e5ddd5 !important;
            background: #fff !important;
            border-radius: 5px;
            cursor: pointer;
        }
        .detail-params {
            flex-grow: 0;
            flex-shrink: 0;
            font-size: 12px;
            line-height: 26px;
            color: #666;
            i {
                display: inline-block;
                vertical-align: middle;
                margin: -2px 8px 0 0;
                color: $red;
                font-weight: 600;
                font-size: 16px;
            }
        }
    }
}
// brand主页
.brand-index {
    position: relative;
    background: #f7f6f3 url(../images/brand_bg.jpg) center bottom no-repeat;
    &:after {
        bottom: 240px;
    }
    @media screen and (max-width: 1440px) {
        background-size: 100% auto;
    }
    &-main {
        position: relative;
        padding: 175px 0 255px 25px;
        max-width: 1300px;
        margin: 0 auto;
        z-index: 9;
        @media screen and (max-width: 1440px) {
            max-width: 1100px;
        }
        @media screen and (max-width: 1220px) {
            padding: 120px 60px 180px 90px;
        }
        @media screen and (max-width: 950px) {
            padding: 80px 40px 120px 80px;
        }
        @media screen and (max-width: 640px) {
            padding: px2rem(50px) px2rem(15px) px2rem(60px);
        }
    }
}
.brand-swiper {
    &.no-init {
        .swiper-wrapper {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
        }
    }
    li {
        position: relative;
        margin-left: -25px;
        width: 194px;
        height: 194px;
        opacity: 0;
        &.swiper-slide-visible {
            opacity: 1;
        }
        &:after {
            content: '';
            position: absolute;
            width: 52px;
            border: 1px dashed $red;
            z-index: 1;
        }
        a {
            position: relative;
            display: block;
            height: 100%;
            z-index: 8;
            &:hover {
                .a-bg {
                    border-color: $red;
                    animation: rotate-pop .4s ease-in-out 1;
                }
                .img img {
                    animation: hvr-pop .4s ease-in-out 1;
                }
            }
        }
        .a-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #fff;
            border: 1px solid #dfdedb;
            transform: rotate3d(0, 0, 1, -45deg);
            border-radius: 6px;
            box-shadow: -10px 10px 18px 5px rgba(0, 0, 0, .08);
            backface-visibility: hidden;
            transition: border .3s .4s;
        }
        .img {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            backface-visibility: hidden;
            img {
                display: block;
                max-width: 138px;
                max-height: 138px;
            }
        }
        &:nth-child(odd) {
            &:after {
                right: -18px;
                bottom: 12px;
                transform: rotate(45deg);
            }
        }
        &:nth-child(even) {
            margin-top: 170px;
            &:after {
                right: -18px;
                top: 12px;
                transform: rotate(-45deg);
            }
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
        @media screen and (max-width: 1440px) {
            margin-left: -30px;
            width: 170px;
            height: 170px;
            .img {
                img {
                    max-width: 120px;
                    max-height: 120px;
                }
            }
            &:nth-child(even) {
                margin-top: 150px;
            }
        }
        @media screen and (max-width: 1220px) {
            width: 145px;
            height: 145px;
            .img {
                img {
                    max-width: 100px;
                    max-height: 100px;
                }
            }
            &:nth-child(even) {
                margin-top: 130px;
            }
        }
        @media screen and (max-width: 1024px) {
            opacity: .4;
        }
        @media screen and (max-width: 640px) {
            margin: 0 0 px2rem(30px);
            padding: 0 px2rem(15px);
            width: 50%;
            height: auto;
            opacity: 1;
            a {
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                background: #fff;
                border-radius: 6px;
                box-shadow: 0 4px 12px 2px rgba(0, 0, 0, .08);
                &:hover {
                    border: 1px solid $red;
                }
            }
            .a-bg {
                display: none;
            }
            &:after {
                display: none;
            }
            &:nth-child(even) {
                margin: 0 0 px2rem(30px);
            }
            .img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                img {
                    max-width: 66%;
                    max-height: 66%;
                }
            }
        }
    }
}
@-webkit-keyframes rotate-pop {
    50% {
        -webkit-transform: rotate3d(0, 0, 1, -45deg) scale(1.06);
        transform: rotate3d(0, 0, 1, -45deg) scale(1.08);
    }
}
@keyframes rotate-pop {
    50% {
        -webkit-transform: rotate3d(0, 0, 1, -45deg) scale(1.06);
        transform: rotate3d(0, 0, 1, -45deg) scale(1.06);
    }
}
@-webkit-keyframes hvr-pop {
    50% {
        -webkit-transform: scale(1.06);
        transform: scale(1.08);
    }
}
@keyframes hvr-pop {
    50% {
        -webkit-transform: scale(1.06);
        transform: scale(1.06);
    }
}
.brand-list {
    &-ul {
        li {
            &:not(:first-child) {
                margin-top: px2rem(30px);
            }
            &:nth-child(even) {
                .img {
                    order: 2;
                }
            }
            .box {
                display: flex;
                align-items: center;
                background: #fff;
                &:hover {
                    h3 {
                        color: $red;
                        transition: all .3s;
                    }
                    .img:after {
                        transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, -45deg) translate3d(0, 105%, 0);
                    }
                }
            }
            .img {
                display: block;
                position: relative;
                width: 50%;
                height: 0;
                padding-bottom: (401/630)*50%;
                overflow: hidden;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 100%;
                    height: auto;
                }
                &:after {
                    transition: all 1s;
                    content: '';
                    z-index: 1;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;;
                    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, -45deg) translate3d(0, -105%, 0);
                    background-color: rgba(255, 255, 255, .3);
                }
            }
            .info {
                flex: 1;
                padding: 0 80px;
                &-params {
                    color: #666;
                    font-size: 12px;
                    line-height: 26px;
                    i {
                        display: inline-block;
                        vertical-align: middle;
                        margin: -2px 8px 0 0;
                        color: $red;
                        font-weight: 600;
                        font-size: 16px;
                    }
                }
                .info-list {
                    display: flex;
                    flex-wrap: wrap;
                    .list-li {
                        flex: 1;
                        display: block;
                        background-color: #bf3434;
                        font-size: 15px;
                        line-height: 30px;
                        text-align: center;
                        color: #fff;
                        border-left: 1px solid #000;
                        white-space: nowrap;
                    }
                    .list-li:hover {
                        opacity: .9;
                    }
                }
            }
            h3 {
                font-size: 28px;
                color: #333;
            }
            .p {
                margin: 15px 0 18px;
                max-height: 150px;
                font-size: 12px;
                line-height: 30px;
                color: #666;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                word-break: break-all;
            }
            @media screen and (max-width: 1200px) {
                .info {
                    padding: 0 50px;
                    .info-list {
                        .list-li {
                            flex: none;
                            width: 33.3%;
                        }
                    }
                }
                h3 {
                    font-size: 24px;
                }
                .p {
                    margin: 10px 0 15px;
                }
            }
            @media screen and (max-width: 950px) {
                .info {
                    padding: 0 40px;
                }
                h3 {
                    font-size: 22px;
                }
                .p {
                    max-height: 90px;
                    -webkit-line-clamp: 3;
                }
            }
            @media screen and (max-width: 768px) {
                .box {
                    display: block;
                }
                .img {
                    width: 100%;
                    padding-bottom: (401/630)*100%;
                }
                .info {
                    padding: px2rem(35px) px2rem(30px) px2rem(40px);
                }
                h3 {
                    font-size: 20px;
                }
                .p {
                    margin: 8px 0 12px;
                    line-height: 28px;
                    max-height: 5*28px;
                    -webkit-line-clamp: 5;
                }
            }
        }
    }
}