$dealerColor: #ef8243;
.trend-bd {
    box-shadow: 0 15px 10px -9px #ccc;
    margin-bottom: 100px;
    max-width: 1200px;
    margin: 0 auto;
    * {
        box-sizing: border-box;
    }
}
.invest-page {
    overflow: hidden;
    background-color: #f4f4f4;
    * {
        box-sizing: border-box;
    }
    h2 {
        padding-top: 55px;
        padding-bottom: 25px;
        text-align: center;
        font-size: 28px;
    }
}

.stock-con {
    max-width: 1200px;
    margin: px2rem(50px) auto px2rem(120px);
    padding: 20px;
    background-color: #fff;
    .left {
        float: left;
        width: 350px;
        padding-top: 50px;
        padding-left: 70px;
        h3 {
            display: inline-block;
            font-size: 48px;
            font-weight: 700;
            color: #ff592d;
            font-family: 'Arial';
            margin-bottom: 20px;
        }
        em {
            vertical-align: top;
            display: inline-block;
            background-image: url('../images/relation-san.png');
            margin-top: 8px;
            margin-left: 7px;
            width: 12px;
            height: 11px;
        }
        .name {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 8px;
            color: #666;
        }
        .num {
            font-size: 14px;
            color: #666;
        }
    }
    .right {
        margin-left: 350px;
        padding: 40px 60px 50px;
        color: #fff;
        background-image: url('../images/gp-bg.png');
        background-size: cover;
        ul {
            padding-bottom: 20px;
            border-bottom: 1px solid #fad09d;
            overflow: hidden;
        }
        li {
            float: left;
            width: 25%;
            border-right: 1px solid #fad09d;
            &:first-child {
                border-left: 1px solid #fad09d;
            }
        }
        .num {
            font-family: 'Arial';
            font-size: 36px;
            padding-left: 32px;
        }
        .name {
            font-size: 14px;
            padding-left: 32px;
        }
        .num,
        .name{
            font-weight: 700;
        }
        .time {
            margin-top: 15px;
            font-size: 16px;
            margin-bottom: 5px;
        }
        .source {
            font-size: 16px;
        }
    }
}

@media(max-width:1050px) {
    .stock-con {
        .left {
            float: none;
            padding: 50px 0;
            text-align: center;
            margin: 0 auto;
        }
        .right {
            margin-left: 0;
        }
    }
}

@media(max-width:767px) {
    .stock-con {
        margin-left: 12px;
        margin-right: 12px;
        .left {
            padding: 25px 0;
            width: 100%;
            h3 {
                margin-bottom: 10px;
            }
        }
        .right {
            padding: 20px 10px 40px;
            ul {
                padding-bottom: 10px;
            }
            li {
                width: 50%;
                border-right: 0;
                text-align: center;
                margin-bottom: 10px;
                &:first-child {
                    border-left: 0
                }
            }
            .num {
                font-size: 32px;
                padding-left: 0;
            }
            .name {
                padding-left: 0;
            }
            .time {
                margin-top: 15px;
                font-size: 16px;
                margin-bottom: 5px;
            }
        }
    }
}

// 走势
// .trend-bd {
//     box-shadow: 0 15px 10px -9px #ccc;
//     margin-bottom: 100px;
//     .trend-top {
//         padding-top: 35px;
//         padding-bottom: 20px;
//         padding-left: 90px;
//         background-color: #fff;
//         &:hover {
//             h3 {
//                 color: $dealerColor;
//             }
//         }
//         h3 {
//             transition: color .3s ease-out;
//             overflow: hidden;
//             text-overflow: ellipsis;
//             white-space: nowrap;
//             font-size: 30px;
//             margin-bottom: 15px;
//         }
//         p {
//             color: #777;
//             font-size: 14px;
//             margin-bottom: 10px;
//         }
//     }
//     .box {
//         display: block;
//         padding-top: 25px;
//         padding-left: 90px;
//         padding-bottom: 25px;
//         background-color: #fbfbfb;
//         &:hover {
//             h3 {
//                 color: $dealerColor;
//             }
//         }
//         &:nth-child(odd) {
//             background-color: #fff;
//         }
//         h3 {
//             transition: color .3s ease-out;
//             overflow: hidden;
//             text-overflow: ellipsis;
//             white-space: nowrap;
//             color: #333;
//             font-size: 18px;
//             margin-bottom: 5px;
//         }
//         p {
//             font-size: 16px;
//             color: #939393;
//         }
//     } 
// }

@media(max-width:767px) {
    .trend-bd {
        margin-left: 12px;
        margin-right: 12px;
        margin-bottom: 60px;
        .trend-top {
            padding: 15px 10px;
            h3 {
                font-size: 24px;
                margin-bottom: 15px;
            }
        }
        .box {
            padding: 10px 15px;
        }
    }
}