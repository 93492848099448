// feddback
.feedback-top {
    display: flex;
    margin-top: 30px;
    padding: 30px 55px 30px 36px;
    background: #fff;
    border: 1px solid #efeeeb;
    .img {
        margin-right: 55px;
        width: 569px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    @media screen and (max-width: 1200px) {
        padding: px2rem(30px) 40px px2rem(30px) px2rem(30px);
        .img {
            margin-right: 40px;
            width: 450px;
        }
    }
    @media screen and (max-width: 950px) {
        display: block;
        padding-right: px2rem(30px);
        .img {
            margin-right: 0;
            width: 100%;
            height: 0;
            padding-bottom: (421/569)*100%;
        }
    }
    @media screen and (max-width: 640px) {
        margin-top: px2rem(50px);
    }
}

.feedback-form {
    flex: 1;
    padding: 18px 0 40px;
    h2 {
        margin-bottom: 16px;
        text-align: center;
        font-size: 24px;
        color: #555;
    }
    input,textarea {
        display: block;
        border: none;
        outline: none;
        background: none;
        color: #666;
    }
    .input-box, .textarea-box {
        margin-bottom: 8px;
        color: #666;
        background: #f5f5f5;
    }
    .input-box {
        display: flex;
        padding: 0 px2rem(22px);
        height: 46px;
        line-height: 46px;
        .input {
            flex: 1;
            input {
                width: 100%;
                line-height: 46px;
            }
        }
    }
    .input-text {
        font-size: 14px;
    }
    .textarea-box {
        padding: 8px px2rem(22px);
        line-height: 28px;
        textarea {
            width: 100%;
            resize: none;
        }
    }
    .qrcode-box {
        display: flex;
        align-items: center;
        .input-box {
            flex: 1;
        }
        .qrcode-img {
            margin: 0 px2rem(10px) 8px;
            img {
                max-height: 46px;
                width: auto;
                cursor: pointer;
            }
            .fadeLink {
                color: #666;
                cursor: pointer;
            }
        }
    }
    input[type='submit'] {
        display: block;
        margin-top: 2px;
        width: 100%;
        height: 35px;
        line-height: 35px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        outline: none;
        border: none;
        background: $red;
        cursor: pointer;
        transition: all .3s;
        &:hover {
            opacity: .85;
        }
    }
    @media screen and (max-width: 1200px) {
        padding-bottom: 30px;
        h2 {
            font-size: 22px;
        }
    }
    @media screen and (max-width: 950px) {
        padding: 20px 0 10px;
    }
    @media screen and (max-width: 640px) {
        padding-top: 15px;
        h2 {
            margin-bottom: 12px;
            font-size: 17px;
        }
        .input-box {
            height: 40px;
            line-height: 40px;
            .input {
                input {
                    line-height: 40px;
                }
            }
        }
        .qrcode-box {
            .qrcode-img {
                margin-right: 0;
                img {
                    max-height: 40px;
                }
            }
        }
    }
}

.comment {
    &-main {
        margin-top: 30px;
        padding: 22px 80px 60px 54px;
        background: #fff;
        border: 1px solid #efeeeb;
        @media screen and (max-width: 1200px) {
            padding: 15px 60px 50px 40px;
        }
        @media screen and (max-width: 950px) {
            padding: px2rem(20px) px2rem(30px) px2rem(40px);
        }
    }
    &-cell {
        padding-top: 20px;
        &:not(:last-child) {
            padding-bottom: 25px;
            border-bottom: 1px dashed #d8d8d8;
        }
        @media screen and (max-width: 640px) {
            padding-top: 12px;
            &:not(:last-child) {
                padding-bottom: 16px;
            }
        }
    }
    &-name {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        width: 100%;
        .icon {
            width: 25px;
            height: 25px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        h4 {
            margin: 0 12px 0 10px;
            color: #000;
        }
        .time {
            color: #555;
        }
        @media screen and (max-width: 640px) {
            .icon {
                width: 22px;
                height: 22px;
            }
            h4 {
                margin: 0 8px;
            }
        }
    }
    &-word, &-more {
        margin-left: 35px;
        color: #666;
        line-height: 22px;
        @media screen and (max-width: 640px) {
            margin-left: 30px;
            font-size: 13px;
        }
    }
    &-more {
        margin-top: 20px;
        padding: 28px 36px;
        background: #f5f5f5;
        @media screen and (max-width: 950px) {
            padding: 20px 30px;
        }
        @media screen and (max-width: 640px) {
            margin-top: 10px;
            padding: 14px 18px;
        }
    }
}


// job
.job-top {
    margin-top: 30px;
    @media screen and (max-width: 640px) {
        margin-top: px2rem(50px);
    }
}

.job-select {
    padding: 4px 30px;
    background: #fff;
    border: 1px solid #efeeeb;
    li {
        display: flex;
        padding: 10px 0;
        &:not(:first-child) {
            border-top: 1px solid #f2f2f2;
        }
        .select-title {
            margin: 0 14px 0 -14px;
            padding-left: 15px;
            height: 30px;
            line-height: 30px;
            color: #fff;
            letter-spacing: 2px;
            background: #d92525;
            border-radius: 1px;
        }
        .item-list {
            flex: 1;
            font-size: 0;
            overflow: hidden;
            a {
                position: relative;
                display: inline-block;
                color: #666;
                font-size: 14px;
                line-height: 30px;
                &:not(:last-child) {
                     margin-right: 40px;
                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: -20px;
                        margin-top: -5px;
                        width: 1px;
                        height: 10px;
                        background: #ccc;
                    }
                }
                &:hover, &.on {
                    color: #d92525;
                }
            }
        }
    }
    @media screen and (max-width: 950px) {
        li {
            .item-list {
                a {
                    &:not(:last-child) {
                        margin-right: 24px;
                        &:before {
                            right: -12px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        padding: 4px 10px;
        li {
            padding: 8px 0 5px;
            .select-title {
                margin: 0 10px 0 0;
                padding: 0 3px 0 9px;
                height: 25px;
                line-height: 25px;
                font-size: 13px;
                letter-spacing: 0;
            }
            .item-list {
                a {
                    margin-bottom: 3px;
                    line-height: 25px;
                    &:not(:last-child) {
                        margin-right: 16px;
                        &:before {
                            right: -8px;
                            margin-top: -4px;
                            height: 8px;
                        }
                    }
                }
            }
        }
    }
}

.job-search {
    display: flex;
    margin: 6px 0 14px;
    padding-left: px2rem(26px);
    width: 270px;
    height: 42px;
    line-height: 40px;
    background: #fff;
    border: 1px solid #efeeeb;
    ::-webkit-input-placeholder {
        color: #999;
    }
    ::-moz-placeholder {
        color: #999;
    }
    :-ms-input-placeholder {
        color: #999;
    }
    input {
        color: #999;
        background: none;
        outline: none;
        border: none;
    }
    input[type='text'] {
        flex: 1;
    }
    input[type='submit'] {
        width: 50px;
        text-align: center;
        font-size: 20px;
        cursor: pointer;
        background: url(../images/search_icon.png) left 10px center no-repeat;
        transition: all .3s;
        &:hover {
            background-image: url(../images/search_icon2.png);
        }
    }
    button {
        width: 50px;
        padding: 0 20px 0 10px;
        text-align: center;
        font-size: 20px;
        cursor: pointer;
        transition: all .3s;
        &:hover {
            color: #d92525;
            font-weight: bold;
        }
    }
    @media screen and (max-width: 750px) {
        width: 100%;
        input[type='submit'] {
            width: 40px;
        }
    }
}

.job-main {
    .job-title {
        display: flex;
        margin-bottom: 20px;
        height: 52px;
        line-height: 52px;
        text-align: center;
        color: #fafafa;
        font-size: 16px;
        background: #939393;
        @media screen and (max-width: 950px) {
            font-size: 15px;
        }
        @media screen and (max-width: 750px) {
            margin-bottom: 12px;
            height: 45px;
            line-height: 45px;
            font-weight: bold;
            .name {
                width: 100%;
            }
        }
    }
    .name, .num,.place,.publish,.end {
        padding: 0 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .name {
        width: 30%;
        overflow: visible;
        em {
            position: relative;
            display: inline-block;
            vertical-align: top;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-style: normal;
            box-sizing: border-box;
        }
    }
    .new-tag {
        .name {
            em {
                margin-top: -12px;
                padding: 12px 30px 0;
                &:after {
                    content: "\e711";
                    position: absolute;
                    right: 0;
                    top: 0;
                    font-family: 'iconfont';
                    font-size: 15px;
                    line-height: 1;
                    color: #df9f46;
                }
            }
        }
    }
    .num,.place,.publish,.end {
        width: calc((70% - 70px) / 4);
        @media screen and (max-width: 750px) {
            display: none;
        }
    }
    .job-item {
        &:not(:first-child) {
            margin-top: 15px;
        }
        &.active {
            .icon i {
                transform: rotate(-180deg);
            }
        }
        .item-hd {
            display: flex;
            align-items: center;
            text-align: center;
            padding: 19px 0;
            line-height: 22px;
            font-size: 14px;
            color: #333;
            background: #fff;
            border: 1px solid #efeeeb;
            cursor: pointer;
            &:hover {
                color: $red;
                .icon i {
                    background: $red;
                    color: #fff;
                }
            }
        }
        .icon {
            display: flex;
            align-items: center;
            width: 70px;
            text-align: center;
            justify-content: center;
            i {
                width: 34px;
                height: 34px;
                line-height: 34px;
                color: #414141;
                font-size: 18px;
                border-radius: 50px;
                border: 1px solid #e6e6e6;
                transform: rotate(0);
                transition: all .4s;
            }
        }
        .item-bd {
            display: none;
            padding: 45px 135px 60px;
            background: #fff;
            border-left: 1px solid #efeeeb;
            border-right: 1px solid #efeeeb;
            border-bottom: 1px solid #efeeeb;
            @include articleReset(14px,30px);
            p {
                margin-bottom: 0;
            }
            &-params {
                display: none;
            }
            .line {
                margin: 20px 0;
                height: 0;
                border-bottom: 1px dashed #ababab;
            }
            &-box {
                h4 {
                    margin-bottom: 13px;
                    font-size: 22px;
                    font-size: #333;
                }
            }
            .file-btn {
                margin-top: 15px;
                width: 178px;
                height: 43px;
                line-height: 43px;
                color: $red;
                text-align: center;
                background: url(../images/input_bg.png) center no-repeat;
                background-size: 100% 100%;
                cursor: pointer;
                transition: all .3s;
                &:hover {
                    color: #fff;
                    background-color: $red;
                }
            }
            .file-box {
                position: relative;
                margin-top: 15px;
                width: 178px;
                height: 43px;
                line-height: 43px;
                color: $red;
                text-align: center;
                background: url(../images/input_bg.png) center no-repeat;
                background-size: 100% 100%;
                transition: all .3s;
                &:hover {
                    color: #fff;
                    background-color: $red;
                }
                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    z-index: 8;
                    cursor: pointer;
                }
            }
        }
        @media screen and (max-width: 1200px) {
            .item-bd {
                padding: 40px 60px 60px;
                &-box {
                    h4 {
                        margin-bottom: 10px;
                        font-size: 20px;
                    }
                }
            }
        }
        @media screen and (max-width: 950px) {
            .new-tag {
                .name {
                    em {
                        margin-top: -11px;
                        padding: 11px 28px 0;
                        &:after {
                            font-size: 14px;
                        }
                    }
                }
            }
            .item-hd {
                padding: 15px 0;
                font-size: 13px;
            }
            .item-bd {
                padding: 30px 40px 50px;
            }
        }
        @media screen and (max-width: 750px) {
            &:not(:first-child) {
                margin-top: 8px;
            }
            .item-hd {
                text-align: left;
                padding: 10px px2rem(30px);
                font-size: 14px;
            }
            .name {
                flex: 1;
                padding: 0 px2rem(30px) 0 0;
            }
            .new-tag {
                .name {
                    em {
                        margin-top: -7px;
                        padding: 7px 24px 0 0;
                        &:after {
                            font-size: 12px;
                            top: 2px;
                        }
                    }
                }
            }
            .icon {
                width: auto;
                i {
                    width: 26px;
                    height: 26px;
                    line-height: 26px;
                    font-size: 14px;
                }
            }
            .item-bd {
                padding: px2rem(25px) px2rem(30px) px2rem(60px);
                font-size: 13px;
                line-height: 24px;
                &-params {
                    display: block;
                    padding-bottom: 10px;
                    margin-bottom: 12px;
                    border-bottom: 1px dashed #ababab;
                    p {
                        line-height: 26px;
                    }
                }
                &-box {
                    h4 {
                        margin-bottom: 8px;
                        font-size: 16px;
                    }
                }
                .line {
                    margin: 12px 0;
                }
                .file-btn {
                    width: 149px;
                    height: 36px;
                    line-height: 36px;
                }
            }
        }
    }
}


.upload-resume {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-width: 90%;
    width: 480px;
    padding: 50px;
    background: #fff;
    backface-visibility: hidden;
    z-index: 3001;
    &-bg {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,.5);
        z-index: 3000;
    }
    .close {
        position: absolute;
        top: 0;
        left: 100%;
        margin-left: 1px;
        width: 40px;
        height: 40px;
        background: #fff;
        cursor: pointer;
        transition: all .3s;
        &:before, &:after {
            content: '';
            position: absolute;
            top: 19px;
            left: 5px;
            width: 30px;
            height: 2px;
            background: $red;
            transition: all .3s;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
        &:hover {
            background: $red;
            &:before,&:after {
                background: #fff;
            }
        }
    }
    .input-box {
        display: flex;
        align-items: center;
        overflow: hidden;
    }
    input[type='file'] {
        flex: 1;
        width: 0;
        color: #333;
        font-size: 15px;
    }
    input[type='submit'] {
        margin-left: 10px;
        padding: 0 24px;
        height: 36px;
        line-height: 36px;
        font-size: 16px;
        border: none;
        color: #fff;
        background: $red;
        border-radius: 4px;
        cursor: pointer;
        transition: all .3s;
        &:hover {
            opacity: .85;
        }
    }
    .file-tips {
        margin-top: 15px;
        color: #999;
    }
    @media screen and (max-width: 750px) {
        padding: 48px px2rem(40px) 45px;
        width: 340px;
        .close {
            right: 0;
            left: auto;
            margin-left: 0;
            width: 40px;
            height: 40px;
            background: #ccc;
            border-bottom-left-radius: 50%;
            &:before, &:after {
                top: 18px;
            }
        }
        .input-box {
            display: block;
        }
        input[type='file'] {
            display: block;
            width: auto;
        }
        input[type='submit'] {
            display: block;
            margin: 16px 0 5px;
            width: 100%;
            height: 36px;
            line-height: 36px;
            font-size: 16px;
            border: none;
            color: #fff;
            background: $red;
            border-radius: 4px;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                opacity: .85;
            }
        }
        .file-tips {
            margin-top: 0;
            text-align: center;
        }
    }
    @media screen and (max-width: 380px) {
        width: 300px;
    }
}