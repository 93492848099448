.header {
    height: 112px;
    line-height: 112px;
    @media screen and (max-width: 1280px) {
        height: 100px;
        line-height: 100px;
    }
    @media screen and (max-width: 1024px) {
        display: none;
    }
    .wp1350 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 60px 0 65px; 
        background: #fff;
        height: 100%;
        @media screen and (max-width: 1440px) {
            padding: 0 35px 0 40px;
        }
        @media screen and (max-width: 1280px) {
            margin: 0 30px;
            padding: 0 15px 0 20px;
        }
    }
    .logo {
        margin-right: 20px;
        img {
            height: 91px;
            width: auto;
        }
        @media screen and (max-width: 1280px) {
            img {
                height: 78px;
            }
        }
    }
    .nav {
        font-size: 0;
        li {
            position: relative;
            display: inline-block;
            vertical-align: top;
            margin: 0 20px;
            font-size: 14px;
            &:hover {
                .nav-a {
                    color: $red;
                    &:after {
                        width: 100%;
                    }
                }
                .sub-nav {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        a {
            display: block;
            &:not(.orange) {
                color: #666;
            }
        }
        .nav-a {
            transition: all .3s;
            &.on {
                color: $red;
            }
        }
        .sub-nav {
            position: absolute;
            top: calc(100% - 1px);
            left: -24px;
            min-width: 170px;
            background: #fff;
            visibility: hidden;
            opacity: 0;
            box-shadow: 0 13px 42px 11px rgba(0, 0, 0, .05);
            z-index: 2001;
            &-item {
                &:first-child {
                    border-top: 2px solid $red;
                }
                &:hover {
                    .sub-nav-a {
                        color: #fff;
                        background: $red;
                        border-bottom-color: $red;
                    }
                    .has-child {
                        &:after {
                            content: '\e601';
                        }
                    }
                }
                &.active {
                    .has-child {
                        &:after {
                            content: '\e601';
                        }
                    }
                }
            }
            &-a {
                padding: 0 90px 0 24px;
            }
            .has-child {
                position: relative;
                &:after {
                    position: absolute;
                    right: 15px;
                    content: "\e696";
                    font-family: "iconfont" !important;
                }
            }
            a {
                line-height: 32px;
                white-space: nowrap;
                border-bottom: 1px solid #f0f0f0;
                transition: all .3s;
            }
        }
        .nav3 {
            display: none;
            a {
                padding: 0 80px 0 38px;
                font-size: 13px;
                &:hover {
                    color: $red;
                }
            }
        }
        @media screen and (max-width: 1440px) {
            li {
                margin: 0 16px;
            }
        }
        @media screen and (max-width: 1280px) {
            li {
                margin: 0 12px;
            }
            .sub-nav {
                left: -20px;
                &-a {
                    padding: 0 40px 0 20px;
                    font-size: 13px;
                }
            }
            .nav3 {
                a {
                    padding: 0 40px 0 32px;
                    font-size: 12px;
                }
            }
        }
        @media screen and (max-width: 1100px) {
            li {
                margin: 0 8px;
            }
        }
    }
    &-btn {
        display: flex;
        height: 112px;
        li {
            display: flex;
            align-items: center;
            color: #666;
        }
        .search-box {
            position: relative;
            margin-right: 25px;
        }
        .search-btn {
            display: inline-block;
            vertical-align: middle;
            width: 24px;
            height: 24px;
            line-height: 20px;
            text-align: center;
            font-size: 15px;
            color: #666;
            border: 2px solid #a6a6a6;
            border-radius: 50%;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                color: $red;
                border-color: $red;
            }
        }
        .search-form {
            display: none;
            position: absolute;
            top: 100%;
            right: 0;
            padding: 15px;
            background: rgba(255,255,255,.95);
            z-index: 2000;
            .form {
                display: flex;
                height: 40px;
                line-height: 40px;
                border: 1px solid $red;
            }
            ::-webkit-input-placeholder { 
                color: #666;
            }           
            ::-moz-placeholder { 
                color: #666;
            }           
            :-ms-input-placeholder { 
                color: #666;
            }
            input,button {
                border: none;
                background: none;
                outline: none;
            }
            input {
                padding-left: 12px;
                width: 200px;
                color: #666;
            }
            button {
                width: 40px;
                text-align: center;
                font-size: 24px;
                color: $red;
                cursor: pointer;
                transition: all .3s;
                &:hover {
                    font-weight: bold;
                }
            }
        }
        .stock-box {
            a {
                color: #666;
                &:hover {
                    color: $red;
                }
            }
            i {
                margin-right: 8px;
                font-size: 20px;
            }
        }
        @media screen and (max-width: 1280px) {
            height: 100px;
            .search-box {
                margin-right: 10px;
            }
        }
    }
}

.index-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2000;
}
.inside-header {
    position: relative;
    background: #fff;
    box-shadow: 0 10px 30px 0px rgba(0,0,0,.08);
    z-index: 99;
}

.header-mobile {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 px2rem(30px);
    height: px2rem(92px);
    line-height: px2rem(92px);
    background: #fff;
    z-index: 8888;
    box-shadow: 0 3px 6px rgba(0,0,0,.06);
    &-block {
        display: none;
        height: px2rem(92px);
    }
    a {
        display: block;
    }
    .logo {
        img {
            display: inline-block;
            vertical-align: middle;
            height: px2rem(74px);
        }
    }
    .header-menu {
        font-size: 0;
        li {
            display: inline-block;
            vertical-align: middle;
            color: #666;
            a {
                color: #666;
            }
        }
    }
    .stock-btn {
        font-size: px2rem(30px);
        i {
            margin-right: 5px;
            font-size: px2rem(38px);
        }
    }
    .menu-btn {
        position: relative;
        padding-left: px2rem(18px);
        margin-left: px2rem(18px);
        text-align: center;
        i {
            display: block;
            font-size: px2rem(46px);
            line-height: 1;
        }
        p {
            margin-top: -1px;
            font-size: px2rem(18px);
            line-height: 1;
        }
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: px2rem(-9px);
            width: 1px;
            height: px2rem(18px);
            background: #7f7f7f;
        }
    }
    @media screen and (max-width: 1024px) {
        display: flex;
        &-block {
            display: block;
        }
    }
}

.nav-mobile {
    position: absolute;
    width: px2rem(450px);
    top: px2rem(172px);
    left: 0;
    bottom: 0;
    z-index: 103;
    background: #fff;
    overflow: hidden;
    box-shadow: 2px 0 3px rgba(255,255,255,.3);
    transform: translateX(-100%);
    transition: transform .4s ease;
    &.anim {
        transform: translateX(0);
    }
    &-con {
        display: none;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 9902;
        overflow: hidden;
        .bg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, .3);
            z-index: 102;
        }
    }
    &-top {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: px2rem(92px);
        line-height: px2rem(92px);
        padding: 0 px2rem(30px);
        font-size: px2rem(28px);
        color: #fff;
        background: #575759;
        z-index: 103;
        i {
            display: inline-block;
            vertical-align: middle;
            margin-right: px2rem(10px);
            font-size: px2rem(30px);
        }
    }
    &-search {
        position: absolute;
        left: 0;
        top: px2rem(92px);
        display: flex;
        width: 100%;
        height: px2rem(80px);
        line-height: px2rem(80px);
        background: #ebebeb;
        box-shadow: 2px 0 3px rgba(255,255,255,.3);
        transition: transform .4s ease;
        z-index: 105;
        input,button {
            outline: none;
            border: none;
            background: none;
        }
        input {
            flex: 1;
            width: 100%;
            padding-left: px2rem(30px);
            font-size: px2rem(28px);
            line-height: px2rem(70px);
            color: #999;
            ::-webkit-input-placeholder { 
                color: #999;
            }
            :-moz-placeholder { 
               color: #999;
            }
            ::-moz-placeholder {
               color: #999;
            }
            :-ms-input-placeholder { 
               color: #b2b2b2;
            }
        }
        button {
            width: px2rem(100px);
            text-align: center;
            font-size: px2rem(52px);
            color: #555;
            &:hover {
                color: $red;
            }
        }
    }
    &-wp {
        position: relative;
        ul {
            padding-top: px2rem(22px);
        }
    }
    &-title {
        position: relative;
        margin: 0 px2rem(30px);
        border-bottom: 1px solid #d9d9d9;
        > a {
            position: relative;
            display: block;
            line-height: px2rem(84px);
            font-size: px2rem(28px);
            z-index: 2;
            backface-visibility: hidden;
            width: 100%;
            &:not(.orange) {
                color: #777;
            }
        }
        .nav-mobile-child-btn {
            i {
                right: 0;
            }
        }
    }
    &-child-btn {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-align: right;
        line-height: px2rem(84px);
        font-size: px2rem(26px);
        font-weight: bold;
        z-index: 1;
        color: #777;
        span {
            display: inline-block;
            margin-right: px2rem(40px);
        }
        i {
            position: absolute;
            top: 0;
            line-height: px2rem(84px);
            font-size: px2rem(26px);
            color: #777;
            font-family: "iconfont";
            transition: transform .2s ease;
            &:before {
                content: "\e696";
            }
        }
        &.open {
            i:before {
                content: "\e601";
            }
        }
        + a {
            display: inline-block;
            width: auto;
        }
    }
    .sub {
        padding: 0 px2rem(30px);
        display: none;
        &-a {
            border-bottom: 1px solid #d9d9d9;
            position: relative;
            height: px2rem(84px);
            overflow: hidden;
            a {
                display: inline-block;
                vertical-align: middle;
                padding-left: px2rem(26px);
                line-height: px2rem(84px);
                font-size: px2rem(26px);
                height: px2rem(84px);
                color: #777;
                overflow: hidden;
            }
            .nav-mobile-child-btn {
                i {
                    right: px2rem(25px);
                }
            }
        }
        &-child {
            display: none;
            a {
                display: block;
                padding-left: px2rem(50px);
                line-height: px2rem(84px);
                font-size: px2rem(26px);
                height: px2rem(84px);
                color: #777;
                border-bottom: 1px solid #d9d9d9;
                overflow: hidden;
            }
        }
    }
}