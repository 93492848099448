.index-pagination {
    position: absolute;
    left: 0;
    z-index: 888;
    span {
        background: #fff;
        opacity: 1;
        transition: all .3s ease;
        &.swiper-pagination-bullet-active {
            background: $red;
        }
    }
}
.index-banner {
    position: relative;
    width: 100%;
    padding-bottom: (973/1920)*100%;
    @media screen and (max-width: 750px) {
        padding-bottom: (419/750)*100%;
    }
    &-box {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        .img {
            width: 100%;
            height: 100%;
            background: #fff none no-repeat center center;
            background-size: cover;
        }
    }
    .banner-pagination {
        width: 100%;
        bottom: px2rem(25px);
        text-align: center;
        span {
            width: 12px;
            height: 12px;
            margin: 0 10px;
        }
        @media screen and (max-width: 750px) {
            span {
                margin: 0 5px;
                width: 10px;
                height: 10px;
            }
        }
    }
}

.index-title {
    text-align: center;
    h2 {
        font-size: 40px;
        color: #666;
        font-weight: 300;
        font-family: "Microsoft YaHei";
        &:before, &:after {
            content: '';
            display: inline-block;
            vertical-align: middle;
            margin-top: -2px;
            width: 30px;
            height: 1px;
            background: #de3635;
            border: 1px solid #ea7c7c;
        }
        &:before {
            margin-right: 25px;
        }
        &:after {
            margin-left: 25px;
        }
    }
    p {
        margin-top: 4px;
        font-size: 13px;
        color: #aaa;
        text-transform: uppercase;
    }
    @media screen and (max-width: 1440px) {
        h2 {
            font-size: 36px;
        }
    }
    @media screen and (max-width: 1024px) {
        h2 {
            font-size: 30px;
        }
    }
    @media screen and (max-width: 640px) {
        h2 {
            font-size: px2rem(44px);
            &:before, &:after {
                width: px2rem(40px);
                border-bottom: none;
            }
            &:before {
                margin-right: 12px;
            }
            &:after {
                margin-left: 12px;
            }
        }
        p {
            margin-top: 2px;
            font-size: px2rem(20px);
        }
    }
}

.index-box1 {
    padding: 75px 0 45px;
    background: #fff url(../images/index_bg.jpg) top 480px center no-repeat;
    background-size: 100% auto;
    @media screen and (max-width: 1366px) {
        background-size: cover;
        background-position: top 400px center;
    }
    @media screen and (max-width: 1280px) {
        padding-top: 60px;
    }
    @media screen and (max-width: 1024px) {
        padding-top: 50px;
        background-position: top 360px center;
    }
    @media screen and (max-width: 640px) {
        padding: px2rem(60px) 0 px2rem(65px);
        background-position: top px2rem(400px) center;
    }
}

.index-about {
    margin-bottom: 70px;
    .index-title {
        margin-bottom: 45px;
    }
    @media screen and (max-width: 1280px) {
        margin-bottom: 60px;
        .index-title {
            margin-bottom: px2rem(30px);
        }
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 50px;
    }
    @media screen and (max-width: 640px) {
        margin-bottom: px2rem(60px);
    }
    &-box {
        display: flex;
        padding: 60px 70px 70px;
        background: #fff;
        box-shadow: 0 0 25px 5px rgba(0,0,0,.06);
        border: 1px solid #dbdbdb;
        .about-swiper {
            position: relative;
            flex: 1;
            overflow: hidden;
            li {
                a {
                    position: relative;
                    display: block;
                    height: 0;
                    padding-bottom: (462/853)*100%;
                    overflow: hidden;
                    transition: all .4s;
                    &:hover {
                        opacity: .85;
                    }
                }
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .about-menu {
            display: flex;
            flex-direction: column;
            width: 255px;
            margin-left: 50px;
            li {
                flex: 1;
                &:not(:first-child) {
                    border-top: 1px dashed #d7d7d7;
                }
                &.on, &:hover {
                    .img, h3 {
                        color: $red;
                    }
                }
                .img {
                    margin-right: 10px;
                    width: 79px;
                    height: 79px;
                    line-height: 79px;
                    text-align: center;
                    color: #666;
                    font-size: 36px;
                    background: url(../images/icon_bg.png) no-repeat;
                    background-size: 100% 100%;
                    transition: all .3s;
                }
                .text {
                    flex: 1;
                }
                h3 {
                    font-size: 18px;
                    color: #333;
                    transition: all .3s;
                }
                p {
                    margin-top: 5px;
                    font-size: 12px;
                    color: #777;
                    text-transform: uppercase;
                }
            }
            &-pc {
                li {
                    a {
                        display: flex;
                        align-items: center;
                        height: 100%;
                    }
                }
            }
            &-mobile {
                display: none;
                li {
                    display: block;
                    text-align: center;
                }
            }
        }
        @media screen and (max-width: 1440px) {
            padding: 40px;
            .about-menu {
                margin-left: 40px;
                li {
                    .img {
                        width: 72px;
                        height: 72px;
                        line-height: 72px;
                        font-size: 32px;
                    }
                }
            }
        }
        @media screen and (max-width: 1200px) {
            padding: 30px;
            .about-menu {
                margin-left: 30px;
                li {
                    .img {
                        width: 60px;
                        height: 60px;
                        line-height: 60px;
                        font-size: 28px;
                    }
                }
            }
        }
        @media screen and (max-width: 1024px) {
            display: block;
            padding: px2rem(20px) px2rem(20px) px2rem(40px);
            .about-menu {
                flex-direction: row;
                width: 100%;
                margin: px2rem(30px) 0 0;
                li {
                    &:not(:first-child) {
                        border-top: none;
                    }
                    .img {
                        margin: 0 auto 8px;
                    }
                    p {
                        margin-top: 3px;
                    }
                }
                &-pc {
                    display: none;
                }
                &-mobile {
                    display: flex;
                }
            }
        }
        @media screen and (max-width: 640px) {
            .about-menu {
                li {
                    .img {
                        margin-bottom: 5px;
                        width: px2rem(80px);
                        height: px2rem(80px);
                        line-height: px2rem(80px);
                        font-size: px2rem(40px);
                    }
                    h3 {
                        font-size: px2rem(30px);
                    }
                    p {
                        font-size: px2rem(15px);
                    }
                }
            }
        }
    }
}

.index-brand {
    text-align: center;
    &-menu1 {
        margin: 12px 0 22px;
        font-size: 0;
        li {
            display: inline-block;
            vertical-align: top;
            margin: 0 28px;
            width: 75px;
            height: 75px;
            line-height: 75px;
            background: url(../images/menu_bg.png) no-repeat;
            background-size: 100% 100%;
            cursor: pointer;
            span {
                display: inline-block;
                margin-top: 18px;
                width: 40px;
                height: 40px;
                line-height: 38px;
                text-align: center;
                font-size: 14px;
                color: #333;
                background: #f8f8f8;
                border: 1px solid #555;
                border-radius: 50%;
                transition: all .3s;
            }
            &.on, &:hover {
                span {
                    color: #fff;
                    background: $red;
                }
            }
        }
        @media screen and (max-width: 1024px) {
            margin-bottom: 16px;
        }
        @media screen and (max-width: 640px) {
            margin-bottom: 12px;
            li {
                margin: 0 px2rem(40px);
                width: 56px;
                height: 56px;
                line-height: 56px;
                span {
                    margin-top: 12px;
                    width: 32px;
                    height: 32px;
                    line-height: 32px;
                    font-size: 13px;
                }
            }
        }
    }
    &-menu2 {
        margin-bottom: 30px;
        font-size: 0;
        .swiper-menu {
            display: none;
            overflow: hidden;
        }
        .swiper-menu.no-init {
            .swiper-wrapper {
                width: 100%;
                display: block;
            }
        }
        li {
            position: relative;
            display: inline-block;
            vertical-align: top;
            padding: 0 px2rem(25px);
            width: auto;
            font-size: 16px;
            line-height: 20px;
            color: #676767;
            cursor: pointer;
            &:not(:first-child) {
                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    margin-top: -5px;
                    width: 1px;
                    height: 10px;
                    background: #b0acaa;
                }
            }
            &.on, &:hover {
                color: $red;
            }
        }
        @media screen and (max-width: 1024px) {
            margin-bottom: 20px;
        }
        @media screen and (max-width: 640px) {
            margin-bottom: 12px;
            li {
                font-size: 14px;
            }
        }
    }
    .brand-bd {
        &-item {
            position: relative;
            margin: 0 -9px;
            font-size: 0;
            display: none;
            li {
                display: inline-block;
                vertical-align: top;
                padding: 0 9px;
                &:hover {
                    .img {
                        transform: scale(1.04);
                    }
                }
                a {
                    position: relative;
                    display: block;
                    padding-bottom: (401/630)*100%;
                    border-radius: 4px;
                    overflow: hidden;
                }
                .img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    transition: all .3s;
                }
                &:first-child {
                    width: (870/1300)*100%;
                }
                &:not(:first-child) {
                    width: (430/1300)*100%;
                }
                &:nth-child(3) {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }
            @media screen and (max-width: 1024px) {
                margin: 0 -5px;
                li {
                    padding: 0 5px;
                }
            }
            @media screen and (max-width: 640px) {
                margin: 0 -2px;
                li {
                    padding: 0 2px;
                }
            }
        }
    }
}


.index-box2 {
    padding: 65px 0 95px;
    @media screen and (max-width: 1280px) {
        padding: 60px 0 80px;
    }
    @media screen and (max-width: 1024px) {
        padding: 50px 0 20px;
    }
    @media screen and (max-width: 640px) {
        padding: px2rem(55px) 0 px2rem(30px);
    }
    .index-title {
        margin-bottom: px2rem(30px);
    }
    .index-news {
        display: flex;
        @media screen and (max-width: 1024px) {
            display: block;
        }
        &-left {
            flex: 1;
            order: 2;
            margin-right: 65px;
            li {
                a {
                    display: flex;
                    align-items: center;
                    padding: 25px 35px;
                    &:hover {
                        .date span, .text h3 {
                            color: $red;
                        }
                    }
                }
                &:nth-child(even) {
                    background: #fafafa;
                }
                &:not(:first-child) {
                    a {
                        border-top: 1px dashed #bbb;
                    }
                }
                .date {
                    margin: -6px px2rem(40px) 0 0;
                    text-align: center;
                    color: #555;
                    font-family: "Microsoft YaHei";
                    span {
                        display: block;
                        margin-bottom: 2px;
                        font-size: 40px;
                        line-height: 1;
                        transition: all .3s;
                    }
                    p {
                        font-size: 12px;
                    }
                }
                .text {
                    flex: 1;
                    width: 0;
                    h3 {
                        margin-bottom: 13px;
                        line-height: 1.2;
                        font-size: 15px;
                        color: #131313;
                        transition: all .3s;
                        span {
                            position: relative;
                            display: inline-block;
                            vertical-align: top;
                            max-width: 100%;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            box-sizing: border-box;
                        }
                    }
                    .new-tag {
                        margin-top: -8px;
                        span {
                            padding: 13px 28px 0 0;
                            &:after {
                                content: "\e711";
                                position: absolute;
                                right: 0;
                                top: 0;
                                font-family: 'iconfont';
                                font-size: 14px;
                                line-height: 1;
                                color: #df9f46;
                            }
                        }
                    }
                    .p {
                        max-height: 44px;
                        font-size: 12px;
                        line-height: 22px;
                        color: #777;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        word-break: break-all;
                    }
                }
            }
            @media screen and (max-width: 1440px) {
                margin-right: 50px;
            }
            @media screen and (max-width: 1200px) {
                margin-right: 30px;
                li {
                    a {
                        padding: 18px 20px;
                    }
                }
            }
            @media screen and (max-width: 1024px) {
                margin: px2rem(10px) px2rem(-20px) 0;
                li {
                    padding: 0 px2rem(20px);
                }
            }
            @media screen and (max-width: 640px) {
                li {
                    a {
                        display: flex;
                        padding: 14px px2rem(20px);
                    }
                    .date {
                        margin-right: px2rem(40px);
                        span {
                            font-size: px2rem(60px);
                        }
                        p {
                            font-size: px2rem(20px);
                        }
                    }
                    .text {
                        h3 {
                            margin-bottom: 8px;
                        }
                        .new-tag {
                            span {
                                padding: 12px 26px 0 0;
                                &:after {
                                    font-size: 13px;
                                }
                            }
                        }
                        .p {
                            max-height: 40px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
        &-right {
            order: 3;
            width: 569px;
            @media screen and (max-width: 1200px) {
                width: 500px;
            }
            @media screen and (max-width: 1024px) {
                width: 100%;
            }
        }
        &-swiper {
            position: relative;
            border-radius: 4px;
            overflow: hidden;
            a {
                position: relative;
                display: block;
                height: 0;
                padding-bottom: (357/569)*100%;
                &:hover {
                    img {
                        opacity: .85;
                    }
                }
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                    transition: all .3s;
                }
                h3 {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    padding: 0 110px 0 40px;
                    width: 100%;
                    height: 65px;
                    line-height: 65px;
                    font-size: 16px;
                    color: #fff;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    background: rgba(0,0,0,.3);
                    transition: all .3s;
                }
            }
            .news-pagination {
                bottom: 23px;
                left: auto;
                right: 0;
                margin-right: px2rem(30px);
                width: auto;
                text-align: right;
                span {
                    width: 9px;
                    height: 9px;
                    margin: 0 0 0 9px;
                }
            }
            @media screen and (max-width: 640px) {
                a {
                    h3 {
                        padding: 0 65px 0 px2rem(20px);
                        height: 40px;
                        line-height: 40px;
                        font-size: 15px;
                    }
                }
                .news-pagination {
                    bottom: px2rem(28px);
                    span {
                        width: 7px;
                        height: 7px;
                        margin-left: 7px;
                    }
                }
            }
        }
    }
}