@font-face {
    font-family: 'OpenSans'; 
    src: url('../font/OpenSans.eot');
    src: url('../font/OpenSans.woff') format('woff'),
        url('../font/OpenSans.ttf') format('truetype'),
        url('../font/OpenSans.svg') format('svg');
    font-style: normal;
}

// history
.history-page {
    padding: 36px 0 97px;
    @media screen and (max-width: 1200px) {
        padding-bottom: 80px;
    }
    @media screen and (max-width: 640px) {
        padding: px2rem(50px) 0 px2rem(70px);
    }
}
.history-box {
    margin-top: px2rem(50px);
}
.history-content {
    padding: 74px 64px 98px;
    background: #fff url(../images/history_bg.jpg) center no-repeat;
    background-size: 100% 100%;
    box-shadow: 0 0 18px rgba(0,0,0,.14);
    .img-box {
        position: relative;
        height: 0;
        padding-bottom: (486/1200)*100%;
        overflow: hidden;
        img {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    @media screen and (max-width: 1200px) {
        padding: 50px 40px 60px;
    }
    @media screen and (max-width: 950px) {
        padding: px2rem(20px) px2rem(20px) px2rem(30px);
    }
}
.history-menu {
    position: relative;
    margin-top: 2px;
    padding: 0 100px;
    height: 60px;
    line-height: 60px;
    background: url(../images/history_bg2.jpg) center no-repeat;
    background-size: cover;
    .history-swiper {
        overflow: hidden;
        li {
            text-align: center;
            font-size: 24px;
            font-family: 'OpenSans';
            color: #fff;
            cursor: pointer;
            &:hover, &.on {
                color: #ecdaba;
                transition: all .3s;
            }
        }
    }
    .history-prev, .history-next {
        position: absolute;
        top: 50%;
        margin-top: -12.5px;
        width: 25px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        font-size: 12px;
        color: #8f8880;
        background: #fff;
        border-radius: 50%;
        cursor: pointer;
        outline: none;
        transition: all .3s;
        &:hover {
            background: #ecdaba;
        }
    }
    .history-prev {
        right: 38px;
    }
    .history-next {
        
        left: 38px;
    }
    @media screen and (max-width: 1200px) {
        .history-swiper {
            li {
                font-size: 20px;
            }
        }
    } 
    @media screen and (max-width: 950px) {
        padding: 0 60px;
        height: 50px;
        line-height: 50px;
        .history-swiper {
            li {
                font-size: 18px;
            }
        }
        .history-prev {
            right: 18px;
        }
        .history-next {
            left: 18px;
        }
    } 
    @media screen and (max-width: 640px) {
        padding: 0 40px;
        height: 40px;
        line-height: 40px;
        .history-swiper {
            li {
                width: auto;
                font-size: 15px;
            }
        }
        .history-prev, .history-next {
            margin-top: -10px;
            width: 20px;
            height: 20px;
            line-height: 20px;
            font-size: 10px;
        }
        .history-prev {
            right: 10px;
        }
        .history-next {
            left: 10px;
        }
    } 
}


// honor
.honor-page {
    padding: 36px 0 110px;
    @media screen and (max-width: 1200px) {
        padding-bottom: 80px;
    }
    @media screen and (max-width: 640px) {
        padding: px2rem(50px) 0 px2rem(70px);
    }
}

.honor-content {
    margin: 36px 0 65px;
    img {
        width: 100%;
        height: auto;
    }
    @media screen and (max-width: 1200px) {
        margin-bottom: px2rem(50px);
    }
    @media screen and (max-width: 640px) {
        margin-top: px2rem(50px);
    }
}

.honor-related {
    position: relative;
    padding: 0 62px;
    &-title {
        margin-bottom: 26px;
        text-align: center;
        h2 {
            line-height: 1.2;
            font-size: 36px;
            color: #333;
        }
        p {
            color: #ac9f90;
        }
    }
    .honor-swiper {
        overflow: hidden;
        li {
            padding: 30px 36px 46px;
            height: auto;
            text-align: center;
            background: #fff;
            border: 1px solid #efeeeb;
            &:hover {
                h3 {
                    color: $red;
                }
            }
        }
        .img-box {
            position: relative;
            height: 0;
            padding-bottom: (272/222)*100%;
            overflow: hidden;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                max-width: 100%;
                max-height: 100%;
            }
        }
        h3 {
            max-width: 76%;
            margin: 24px auto 0;
            line-height: 20px;
            color: #555;
        }
    }
    .honor-prev, .honor-next {
        position: absolute;
        top: 50%;
        margin-top: -16px;
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        color: #888;
        background: #fff;
        border: 1px solid #efeeeb;
        border-radius: 50%;
        cursor: pointer;
        outline: none;
        transition: all .3s;
        &:hover {
            color: #fff;
            background: $red;
        }
    }
    .honor-prev {
        left: 10px;
    }
    .honor-next {
        right: 10px;
    }
    .honor-pagination {
        display: none;
        margin-top: 15px;
        text-align: center;
        span {
            margin: 0 2px;
            width: 9px;
            height: 9px;
            opacity: 1;
            background: #fff;
            border: 1px solid #ecdaba;
        }
        .swiper-pagination-bullet-active {
            background: #ecdaba;
        }
    }
    @media screen and (max-width: 1600px) {
        .honor-swiper {
            li {
                padding: 25px 26px 36px;
            }
            h3 {
                margin-top: 18px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        &-title {
            h2 {
                font-size: 30px;
            }
            p {
                font-size: 13px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        padding: 0 48px;
        .honor-swiper {
            li {
                padding: 20px 15px 25px;
                h3 {
                    font-size: 13px;
                    line-height: 19px;
                }
            }
        }
        .honor-prev {
            left: 5px;
        }
        .honor-next {
            right: 5px;
        }
    }
    @media screen and (max-width: 768px) {
        padding: 0;
        &-title {
            margin-bottom: 15px;
            h2 {
                font-size: 22px;
            }
            p {
                font-size: 12px;
            }
        }
        .honor-swiper {
            li {
                padding: 15px 10px 20px;
                h3 {
                    margin-top: 12px;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
        .honor-prev, .honor-next {
            display: none;
        }
        .honor-pagination {
            display: block;
        }
    }
}

.store-list {
    margin-top: 10px;   
    overflow: hidden;
    &-ul {
        font-size: 0;
        li {
            display: inline-block;
            vertical-align: top;
            margin-top: px2rem(20px);
            padding: 0 px2rem(10px);
            width: (100%/3);
            a {
                display: block;
                padding: 16px 16px 45px;
                background: #fff;
                box-shadow: 0 12px 20px -12px rgba(0,0,0,.2);
                transition: all .3s;
                &:hover {
                    transform: translateY(-3px);
                    .img img {
                        opacity: .85;
                        transition: all .3s;
                    }
                    .info {
                        h3 {
                            color: $red;
                            transition: all .3s;
                        }
                    }
                }
            }
            .img {
                position: relative;
                height: 0;
                padding-bottom: (215/356)*100%;
                overflow: hidden;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
            .info {
                padding: 25px 12px 0;
                h3 {
                    margin-bottom: 16px;
                    padding-bottom: 22px;
                    text-align: center;
                    font-size: 20px;
                    color: #333;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    border-bottom: 1px dashed #c1c1c1;
                }
                .p {
                    height: 3*28px;
                    padding: 0 10px;
                    font-size: 12px;
                    line-height: 28px;
                    color: #777;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    word-break: break-all;
                }
            }
        }
    }
    @media screen and (max-width: 950px) {
        &-ul {
            margin: 0 px2rem(-10px);
            li {
                a {
                    padding-bottom: 35px;
                }
                .info {
                    padding-top: 22px;
                    h3 {
                        margin-bottom: 10px;
                        padding-bottom: 16px;
                        font-size: 18px;
                    }
                    .p {
                        height: 3*26px;
                        padding: 0 6px;
                        line-height: 26px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        margin-top: px2rem(30px);
        &-ul {
            li {
                width: 50%;
                a {
                    padding: 10px 10px 20px;
                }
                .info {
                    padding: 12px 8px 0;
                    h3 {
                        margin-bottom: 6px;
                        padding-bottom: 10px;
                        font-size: 15px;
                    }
                    .p {
                        height: 3*24px;
                        padding: 0;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}